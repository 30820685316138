:root {
  --red-color: #a51a17;
  --grey-color: #666666;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Oswald", sans-serif !important;
  font-size: 1rem;
}

a {
  color: #000;
}

a:hover {
  text-decoration: none;
  color: #000;
}

button:focus {
  outline: none;
}

p {
  margin: 0;
}

/* Top Navbar */
.top-nav {
  background-color: #000;
  color: #fff;
}

.top-nav .top-text {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 5px 0;
  margin: 0;
  font-size: 0.8rem;
}

/* Navbar */
.navbar {
  padding: 0 !important;
  background-color: #fff !important;
}

.navbar-nav {
  align-items: center;
}

.navbar-light .navbar-nav .nav-link {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 0.8rem;
  letter-spacing: 2px;
  padding: 0;
  margin-right: 60px;
}

.navbar-brand {
  padding-bottom: 0;
  margin-right: 0;
  width: 60%;
  padding: 0;
}

.hoverItem {
  height: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  top: 99px;
  left: -30px;
  padding: 0.3rem 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.dropdown-item {
  font-size: 0.8rem;
  padding: 0.25rem 1rem;
  color: #fff;
  margin-bottom: 1rem;
  letter-spacing: 3px;
}

.dropdown-item:last-child {
  margin-bottom: 0;
}

.dropdown-item:hover {
  background-color: unset !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.logo {
  height: 100px;
}

.mobile {
  border: 2px solid #000;
  border-radius: 50px;
  padding: 5px 30px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 150px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
}

.mobile .mobile-icon {
  width: 15px;
  margin-right: 15px;
}

.dropdown-toggle::after {
  display: none;
}

/* Hero Section */
.hero {
  width: 100%;
  height: 65vh;
}

.carousel-item {
  height: 100%;
}

.hero-image {
  width: 100%;
  height: 90%;
  min-height: 65vh;
  max-height: 65vh;
}

/* Card Grid */
.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  margin: 50px 0;
}

.card {
  height: 200px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: pre-line;

  border: none;
}

.card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 5;
}

.card:hover:after {
  background-color: rgba(0, 0, 0, 0.3);
}

.card-title {
  font-size: 20px;
  z-index: 20;
  letter-spacing: 2px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}

.arrow {
  z-index: 20;
  width: 20px;
  margin-top: 20px;
}

/* Tradition */
.tradition {
  background-color: var(--grey-color);
  color: #fff;
}

.tradition-header {
  padding: 40px 0 20px 0;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  letter-spacing: 3px;
}

.tradition-text {
  letter-spacing: 3px;
  font-size: 1.2rem;
  line-height: 1.5;
}

.tradition-more {
  width: 125px;
  margin-top: 10px;
  border: 2px solid #fff;
  border-radius: 50px;
  display: inline-flex;
  padding-bottom: 6px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  margin-top: 1.5rem;
}

.tradition-more:hover {
  transform: scale(1.2);
}

.tradition-more-icon {
  width: 1.1rem;
}

.tradition-image {
  width: 100%;
  height: 40vh;
  margin-top: 40px;
}

/* Contacts */
.contacts {
  padding: 30px 0;
  border-top: 5px solid var(--red-color);
}

.contact-text {
  font-size: 14px;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
}

.contact-text-bold {
  font-weight: 500;
}

.contacts-middle p {
  margin-left: 50px;
}

.contacts-right p {
  margin-left: 100px;
}

/* Footer */
.footer {
  background-color: #000;
  color: #fff;
  padding: 25px 0 25px 40px;
}

.footer-text {
  font-size: 12px;
  margin-left: 50px;
  letter-spacing: 1px;
}

/* STORITVE CLASSES */
.main-section {
  padding: 50px 0;
}

.flex-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.main-logo {
  width: 42px;
}

.main-header {
  font-size: 2.5rem;
  margin-bottom: 0;
  margin-left: 10px;
  letter-spacing: 5px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

.header-left {
  display: flex;
  align-items: center;
  margin-left: -52px;
}

.main-text {
  font-size: 1.25rem;
  letter-spacing: 1px;
}

ul {
  list-style-type: none;
  margin-left: 30px;
}

ul li {
  display: block;
  font-size: 1.2rem;
  letter-spacing: 2px;
}

ul li span {
  width: 10px;
  height: 10px;
  border: 1px solid var(--red-color);
  display: inline-block;
  margin-right: 6px;
}

/* Work Time */
.work-time {
  font-size: 1.25rem;
  font-weight: 500;
}

.work-main,
.work {
  margin-bottom: 0;
}

.work-main {
  margin-bottom: 20px;
}

.mobile-red {
  background-color: var(--red-color);
  color: #fff;
  border-radius: 50px;
  padding: 10px 30px;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  transition: all 0.5s ease-in-out;
}

.mobile-red:hover {
  transform: scale(1.2);
}

.mobile-icon {
  width: 20px;
  margin-right: 10px;
}

.mobile-red-text {
  font-weight: 400;
  letter-spacing: 5px;
  font-size: 16px;
}

.col-xl-4 {
  display: flex;
  flex-direction: column;
}

.map-card {	
  position: relative;	
  height: 400px;	
  width: 110%;	
  margin-top: 50px;	
  margin-left: -50px;	
}

a.telefonska {
  color: white;
}
.location-marker{
  width: 100%;
  height: 100%;
}
.location-icon {
 
  font-size: 3rem;
  color: var(--red-color);
}

@media (max-width: 1800px) {
  /* Navbar */
  .navbar-light .navbar-nav .nav-link {
    margin-right: 30px;
  }

  .navbar-brand {
    width: 50%;
  }

  .mobile {
    margin-right: 50px;
  }
}

@media (max-width: 1100px) {
  .mobile {
    margin-right: 10px;
  }

  /* Hero Section */
  .hero {
    height: 35vh;
  }

  .hero-image {
    height: 30vh;
    min-height: 30vh;
  }

  .tradition-image {
    height: 30vh;
  }
}

@media (max-width: 990px) {
  /* Navbar toggle */
  .navbar-nav {
    background-color: #fff;
    height: 100vh;
    width: 100vw;
    align-items: flex-start;
    padding: 20px 40px;
  }

  .nav-link {
    border-bottom: 1px solid #000;
    width: 100%;
  }

  .navbar-light .navbar-nav .nav-link {
    padding: 5px 0;
  }

  .nav-link a {
    font-size: 1.2rem;
  }

  .hoverItem {
    height: unset;
    padding: 2px 0;
    width: 100%;
    position: relative;
  }

  .hoverItem #basic-nav-dropdown {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    border: none;
  }

  .dropdown-toggle::after {
    display: inline-flex;
  }

  .mobile {
    position: absolute;
    bottom: 175px;
    left: 50%;
    transform: translateX(-50%) scale(1.5);
    width: 50%;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile .mobile-icon {
    width: 20px;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .dropdown-menu {
    top: 40px;
    left: 0;
    padding: 0.3rem 0;
    background-color: #000;
    width: 100%;
  }

  .logo {
    height: 70px;
  }

  /* Hero Section */
  .hero {
    height: 35vh;
  }

  .hero-image {
    height: 30vh;
    min-height: 30vh;
  }

  /* Card Grid */
  .card {
    height: 150px;
    max-width: 350px;
    margin: 0 auto;
  }

  .card-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 0;
    margin-bottom: 50px;
  }

  .card-title {
    font-size: 1rem;
  }

  /* Tradition */
  .tradition {
    padding-top: 30px;
  }

  .tradition-header {
    letter-spacing: 1px;
    padding: 0;
    margin-bottom: 20px;
    font-size: 1.3rem;
  }

  .tradition-text {
    letter-spacing: 1px;
    font-size: 1.2rem;
    line-height: 1.3;
  }

  .tradition-image {
    height: 30vh;
    margin-top: 25px;
  }

  /* Contacts */
  .contact-text {
    font-size: 0.85rem;
  }

  .contacts-middle p {
    margin-left: 0;
  }

  .contacts-right p {
    margin-left: 0;
  }

  /* Footer */
  .footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
  }

  .footer-text {
    font-size: 12px;
    margin-left: 40px;
    letter-spacing: 1px;
  }

  /* STORITVE CLASSES */
  .main-section {
    padding: 0;
  }

  .flex-row {
    margin-bottom: 20px;
  }

  .main-logo {
    width: 30px;
  }

  .main-header {
    font-size: 1.7rem;
  }

  .header-left {
    margin-left: 0;
  }

  .main-text {
    font-size: 1.2rem;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  /* Work Time */
  .work-time {
    font-size: 1.2rem;
    margin-top: 30px;
  }

  .work-main {
    margin-bottom: 10px;
  }

  .mobile-red {
    margin: 20px 0 40px;
  }

  /* Zemljevid */
  .map-card {	
    height: 300px;	
    width: 100%;	
    margin-top: 0;	
    margin-left: 0;	
    margin-bottom: 40px;	
  }	
}


@media (max-width: 500px) {
  /* Top Navbar */
  .top-nav .top-text {
    font-size: 0.7rem;
  }

  /* Navbar */
  .logo {
    height: 50px;
  }

  .mobile {
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%) scale(1.2);
    font-size: 0.9rem;
    width: 60%;
  }

  .mobile .mobile-icon {
    width: 15px;
  }

  /* Hero Section */
  .hero {
    height: 30vh;
  }

  .hero-image {
    height: 25vh;
    min-height: 25vh;
  }

  /* Card Grid */
  .card {
    height: 150px;
  }

  .card-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }

  /* Tradition */
  .tradition-header {
    font-size: 1rem;
  }

  .tradition-text {
    font-size: 0.9rem;
  }

  .tradition-image {
    height: 25vh;
    margin-top: 25px;
  }

  /* Contacts */
  .contact-text {
    font-size: 0.85rem;
  }
  .contact-text:last-child {
    margin-bottom: 0;
  }

  .contact {
    margin-bottom: 20px;
  }

  .contact:last-child {
    margin-bottom: 0;
  }

  /* Footer */
  .footer-text {
    font-size: 0.7rem;
    margin-left: 0;
    padding: 0 20px;
  }

  /* STORITVE CLASSES */

  .main-text {
    font-size: 1rem;
  }

  /* Work Time */
  .work-time {
    font-size: 1rem;
    margin-top: 20px;
  }

  .work-main {
    margin-bottom: 10px;
  }

  ul {
    margin-left: 20px;
  }

  ul li {
    font-size: 0.9rem;
  }

  /* Zemljevid */
  .map-card {
    height: 200px;
    width: 100%;
    margin-bottom: 40px;
  }
}

.phonecall {
  display: none;
}

@media (max-width: 1100px) {
  .phonecall {
    display: block;
    width: 13%;
    height: 13%;
    position: fixed;
    bottom: 0;
    left: 80%;
    z-index: 100;
    object-fit: contain !important;
  }
}
.navbar-light .navbar-toggler {
  border: none;
}
img{
  object-fit: cover;
}

.carousel-control-next-icon{
  display: none!important;
}
.carousel-control-prev-icon{
  display: none!important;
}
.carousel-indicators .active{
  display:none!important;
}

.gmnoprint{
  display: none!important;
}